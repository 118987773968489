/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withPrefix, graphql } from 'gatsby';

// Helpers
import { filterResults } from '../../helpers/helpers';
import { checkNull } from '../../helpers/check-null';

// Layouts
import Default from '../../layouts/default';
import Seo from '../../layouts/seo';

// Component
import Section from '../../components/section';
import Form from '../../components/form';
import InputSwitch from '../../components/input-switch';
import Select from '../../components/select';
import PageHeader from '../../components/page-header';
import InputField from '../../components/input-field';
import ResultListing from '../../components/result-listing';
import ShareResults from '../../components/share-results';
import Content, { HTMLContent } from "../../components/content-renderer";

// Helpers
import withShareResults from '../../helpers/with-share-results';

const IncomeTax = (props) => {
	const { getShareLink, selectedYearParam } = withShareResults();
	const PageContent = HTMLContent || Content;

	const pageInfo = props.data.toolInfo;
    const definitions = props.data.definitions.nodes;
    const headerImage = pageInfo.frontmatter.image.relativePath;

	// React Redux hooks
	const dispatch = useDispatch();
	const figures = useSelector(state => state.yearlyFigures);
	const query = useSelector(state => state.incomeTax);

	useEffect(() => {
        return () => {
			dispatch({ type: 'RESET_YEAR' });
			dispatch({ type: 'RESET_INCOME_TAX_SELECTIONS' });
		};
    }, []);

	//  Applies url params on load
	useEffect(() => {
		if (selectedYearParam) {
			dispatch({ type: 'SET_YEAR', payload: selectedYearParam });
		}

		dispatch({ type: 'APPLY_INCOME_TAX_URL_PARAMS' });
	}, [selectedYearParam]);

	// Bonus pay as a fixed amount or as a percentage of salary
	const bonusPay = query.bonusAsPercentage ? checkNull(query.salary) * (checkNull(query.bonus) / 100) : checkNull(query.bonus);
	
	const grossIncome = checkNull(query.salary) + checkNull(bonusPay);
	
	// Calculate salary based on 'Paid per' period input
	const calcGrossIncome = () => {
        const numberDays = query.workingDays === '' || isNaN(query.workingDays) ? 1 : parseFloat(query.workingDays);
        const numberHours = query.workingHours === '' || isNaN(query.workingHours) ? 1 : parseFloat(query.workingHours);
        
        return (query.paidPer === 'year') ? grossIncome :
            (query.paidPer === 'month') ? grossIncome * 12 :
            (query.paidPer === 'fortnight') ? grossIncome * 26 :
            (query.paidPer === 'week') ? grossIncome * 52 :
            (query.paidPer === 'day') ? grossIncome * numberDays * 52 :
            (query.paidPer === 'hour') ? grossIncome * numberHours * numberDays * 52 :
            null;
    };

	// Pension calculation
	const calcPension = calcGrossIncome() * (checkNull(query.pensionContribution) / 100);

	const adjustedNetIncome = calcGrossIncome() - calcPension;

	// Personal Allowance Reduction
	// Personal Allowance goes down by £1 for every £2 that adjusted net income (gross pay - pension) is above 100,000 (https://www.gov.uk/income-tax-rates/income-over-100000)
	const personalAllowanceReduction = adjustedNetIncome > 100000 ? (adjustedNetIncome - 100000) / 2 : 0;

	// Blindness Allowance is applied apply new figure (https://www.gov.uk/blind-persons-allowance/what-youll-get)
	const checkBlindnessAllowance = query.blind ? figures.personalAllowance + figures.blindAllowance : figures.personalAllowance;

	// Calculate corrected Personal Allowance with reduction if gross wage > 100,000
	const calcPersonalAllowance = checkBlindnessAllowance - personalAllowanceReduction;
	const calcCorrectedPersonalAllowance = calcPersonalAllowance < 0 ? '0.00' : calcPersonalAllowance;

	// Taxable Income calculation
	const calcTaxableIncome = calcGrossIncome() - calcCorrectedPersonalAllowance - calcPension;
	const calcCorrectedTaxableIncome = calcTaxableIncome < 0 ? '0.00' : calcTaxableIncome;
	
	// MCA (Married Couples Allowance) calculation
	// https://www.citizensadvice.org.uk/debt-and-money/tax/income-tax-how-much-should-you-pay/income-tax-allowances-and-amounts/
	// Deducts 10% of eligible MCA from Income Tax
	// Gross income below income threshold: (max allowance)
	// Gross income above income threshold: falls £1 for every £2 over minimum theshold to minimum (min allowance)
	const calcMCATaxRebate = calcGrossIncome() < figures.mcaIncomeThreshold ? figures.mcaMaxAllowance * 0.1 :
		calcGrossIncome() >= figures.mcaIncomeThreshold ? (figures.mcaMaxAllowance - ((calcGrossIncome() - figures.mcaIncomeThreshold) / 2)) * 0.1 :
		null;

	const calcCorrectedMCATaxRebate = !query.mca ? 0 :
		calcMCATaxRebate < figures.mcaMinAllowance ? figures.mcaMinAllowance :
        calcMCATaxRebate;
	
	// Income Tax calculation
	// https://www.gov.uk/income-tax-rates
	// 0% up to Personal Allowance
	// Basic Rate Tax between Personal Allowance and Basic Rate Band
	// Higher Rate Tax between Basic Rate Band and Higher Rate Band
	// Additional Rate Tax for earnings above  Higher Rate Band
    const personalAllowanceRationalised = calcPersonalAllowance < 0 ? 0 : calcPersonalAllowance;
    const basicRate = personalAllowanceRationalised + (figures.basicRateBand - figures.personalAllowance);
    const calcIncomeTax = adjustedNetIncome <= personalAllowanceRationalised ? '0.00' :
        adjustedNetIncome > personalAllowanceRationalised && adjustedNetIncome <= basicRate ? ((adjustedNetIncome - personalAllowanceRationalised) * figures.basicRateTax) - calcCorrectedMCATaxRebate :
        adjustedNetIncome > basicRate && adjustedNetIncome <= figures.higherRateBand ? ((basicRate - personalAllowanceRationalised) * figures.basicRateTax) + ((adjustedNetIncome - basicRate) * figures.higherRateTax) - calcCorrectedMCATaxRebate :
        adjustedNetIncome > figures.higherRateBand ? ((basicRate - personalAllowanceRationalised) * figures.basicRateTax) + ((figures.higherRateBand - basicRate) * figures.higherRateTax) + ((adjustedNetIncome - figures.higherRateBand) * figures.additionalRateTax) - calcCorrectedMCATaxRebate :
        null;

	const calcCorrectedIncomeTax = (calcIncomeTax < 0) ? '0.00' : calcIncomeTax;
    
    return (
        <Default>
            <Seo
                title={pageInfo.frontmatter.title}
                description={pageInfo.frontmatter.description}
            />
			
			<PageHeader
				heading={pageInfo.frontmatter.title}
				imageSource={`${withPrefix("/")}img/${headerImage}`}
				modalContent={(
					<PageContent content={pageInfo.html} />
				)}
				description={pageInfo.frontmatter.description}
				breadcrumbs={[
                    {name: 'Home', url: '/'},
                    {name: 'Personal finance', url: '/personal-finance'},
                ]}
			/>
			
			<Section label='Calculator'>
				<Form>
					<Form.Fieldset>
						<Form.Row>
							<InputField
								id='salary'
								labelText='Salary'
								modalHeading={definitions[4].frontmatter.title}
								modalContent={<PageContent content={definitions[4].html} />}
								placeholder='0'
								symbol='currency'
								min={0}
								onChange={(event) => dispatch({
                                    type: 'SET_INCOME_TAX_SALARY',
                                    payload: event.target.value,
                                })}
								required
								value={query.salary}
							/>

							<InputField
								id='bonus'
								labelText='Bonus'
								modalHeading={definitions[1].frontmatter.title}
								modalContent={<PageContent content={definitions[1].html} />}
								placeholder='0'
								symbol={query.bonusAsPercentage ? 'percentage' : 'currency'}
								min={0}
								max={query.bonusAsPercentage ? 100 : undefined}
								onChange={(event) => dispatch({
									type: 'SET_INCOME_TAX_BONUS',
									payload: event.target.value,
								})}
								value={query.bonus}
								checkboxText='Bonus as %'
								checkboxValue={query.bonusAsPercentage}
								checkboxOnChange={() => dispatch({
									type: 'SET_INCOME_TAX_BONUS_AS_PERCENTAGE',
									payload: !query.bonusAsPercentage,
								})}
							/>
		
							<Select
								labelText="Paid per"
								id="paid-select"
								onChange={(event) => dispatch({
									type: 'SET_INCOME_TAX_PAID_PER',
									payload: event.target.value,
								})}
								value={query.paidPer}
							>
								<Select.Option value="year">Year</Select.Option>
								<Select.Option value="month">Month</Select.Option>
								<Select.Option value="fortnight">Fortnight</Select.Option>
								<Select.Option value="week">Week</Select.Option>
								<Select.Option value="day">Day</Select.Option>
								<Select.Option value="hour">Hour</Select.Option>
							</Select>
						</Form.Row>
								
						<Form.Row>
							<InputField
                                id='pension'
                                labelText='Pension contribution'
                                modalHeading={definitions[2].frontmatter.title}
                                modalContent={<PageContent content={definitions[2].html} />}
                                placeholder='0'
                                symbol='percentage'
                                min={0}
                                max={100}
                                onChange={(event) => dispatch({
                                    type: 'SET_INCOME_TAX_PENSION',
                                    payload: event.target.value,
                                })}
                                value={query.pensionContribution}
                            />

							<Select
								labelText="Tax year"
								id="tax-year-select"
								onChange={(event) => dispatch({
									type: 'SET_YEAR',
									payload: event.target.value,
								})}
								value={figures.selectedYear}
							>
								<Select.Option value='2024'>2024/25</Select.Option>
								<Select.Option value='2023'>2023/24</Select.Option>
								<Select.Option value='2022'>2022/23</Select.Option>
								<Select.Option value='2021'>2021/22</Select.Option>
								<Select.Option value='2020'>2020/21</Select.Option>
								<Select.Option value='2019'>2019/20</Select.Option>
								<Select.Option value='2018'>2018/19</Select.Option>
								<Select.Option value='2017'>2017/18</Select.Option>
								<Select.Option value='2016'>2016/17</Select.Option>
							</Select>
						</Form.Row>

						<Form.Row>
							<InputField
								id='days-per-week'
								labelText='Working days per week'
								placeholder='5'
								min={1}
								max={7}
								onChange={(event) => dispatch({
									type: 'SET_INCOME_TAX_WORKING_DAYS',
									payload: event.target.value,
								})}
								value={query.workingDays}
							/>
		
							<InputField
								id='hours-per-day'
								labelText='Working hours per day'
								placeholder='7'
								min={1}
								max={24}
								onChange={(event) => dispatch({
									type: 'SET_INCOME_TAX_WORKING_HOURS',
									payload: event.target.value,
								})}
								value={query.workingHours}
							/>
						</Form.Row>
								
						<Form.Row>
							<InputSwitch
								id="blind-switch"
								onChange={() => dispatch({
									type: 'SET_INCOME_TAX_BLIND',
									payload: !query.blind,
								})}
								checked={query.blind}
								modalHeading={definitions[0].frontmatter.title}
								modalContent={<PageContent content={definitions[0].html} />}
							>
								Blind
							</InputSwitch>
		
							<InputSwitch
								id="mca-switch"
								onChange={() => dispatch({
									type: 'SET_INCOME_TAX_MCA',
									payload: !query.mca,
								})}
								checked={query.mca}
								modalHeading={definitions[3].frontmatter.title}
								modalContent={<PageContent content={definitions[3].html} />}
							>
								MCA
							</InputSwitch>
						</Form.Row>
					</Form.Fieldset>
				</Form>
			</Section>

			<Section
				heading='Your results'
				label='Calculation results'
				variants={['padded', 'secondary']}
			>
				<ResultListing
					select={[
						{name: 'Year', value: 'year'},
						{name: 'Month', value: 'month'},
						{name: 'Week', value: 'week'},
						{name: 'Day', value: 'day'},
						{name: 'Hour', value: 'hour'},
					]}
					selectID='results-filter'
					selectOnChange={(event) => dispatch({
						type: 'SET_INCOME_TAX_FILTER_RESULTS',
						payload: event.target.value,
					})}
					selectValue={query.filterResults}
				>
					<ResultListing.Item
						heading='Gross income'
						result={filterResults(calcGrossIncome(), query.filterResults, query.workingDays, query.workingHours)}
						subheading={`per ${query.filterResults}`}
						modalHeading={definitions[2].frontmatter.title}
						modalContent={<PageContent content={definitions[2].html} />}
					/>

					<ResultListing.Item
						heading='Taxable income'
						result={filterResults(calcCorrectedTaxableIncome, query.filterResults, query.workingDays, query.workingHours)}
						subheading={`per ${query.filterResults}`}
					/>

					<ResultListing.Item
						heading='Income tax'
						result={filterResults(calcCorrectedIncomeTax, query.filterResults, query.workingDays, query.workingHours)}
						subheading={`per ${query.filterResults}`}
					/>
				</ResultListing>

				<div className='summary-card__controls'>
					<ShareResults url={getShareLink(query, figures)} />
				</div>
			</Section>
        </Default>
    );
};

export default IncomeTax;

export const IncomeTaxQuery = graphql`
    query IncomeTaxQuery($id: String!) {
        toolInfo: markdownRemark(id: { eq: $id }) {
            id
            html
            frontmatter {
                description
                image {
                    relativePath
                }
                title
            }
        }
		definitions: allMarkdownRemark(
            filter: {fileAbsolutePath: {
                regex: "/src/definitions/salary|src/definitions/bonus-pay|src/definitions/blindness-allowance|src/definitions/married-couples-allowance|src/definitions/gross-income/"
            }},
            sort: {order: ASC, fields: frontmatter___title}
        ) {
            nodes {
                html
                frontmatter {
                    title
                }
            }
        }
    }
`;